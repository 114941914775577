@use "../../styles/partials/typography-mixins" as *;
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;
@use "../../styles/partials/colors" as *;

.about {
  display: flex;
  flex-direction: column;
  gap: $tablet-padding;
  @include tablet {
    gap: $large-space-between;
  }

  &__section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: $mobile-padding;
    @include tablet {
      align-items: flex-start;
      gap: $mid-space-between;
    }
    &-header {
      @include header;
    }

    &-title {
      @include subheader;
      color: $beige;
    }

    &-content {
      @include body-large;
      text-align: center;
      @include tablet {
        text-align: left;
      }
    }
  }
}

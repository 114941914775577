@use "../../styles/partials/typography-mixins" as *;
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/breakpoints" as *;
@use "../../styles/partials/variables" as *;
@use "../../styles/partials/colors" as *;

.header {
  @include body-large;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding: $mobile-padding;
  gap: $small-space-between;
  @include tablet {
    flex-direction: row;
    justify-content: space-between;
    padding: $tablet-padding;
  }
  @include desktop {
    max-width: $breakpoint-desktop;
    margin: 0 auto;
  }

  section {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    @include tablet {
      width: fit-content;
    }
  }
  &__logo {
    &-image {
      width: 10rem;
    }
    &:hover {
      animation: blink 1.3s ease-in-out infinite;
    }
    @keyframes blink {
      0%,
      20%,
      100% {
        opacity: 1;
      }
      10%,
      30% {
        opacity: 0;
      }
    }
  }
  &__menu-icon {
    display: block;
    cursor: pointer;
    width: 1.4rem;
    transition: transform 0.3s ease;
    &:hover {
      transform: scale(1.3);
    }
    @include tablet {
      display: none;
    }
  }

  &__nav {
    display: none;
    &.visible {
      display: flex;
      justify-content: center;
    }
    @include tablet {
      display: flex;
    }
  }
  .nav-list__item--active {
    font-weight: bold;
  }
}

@use "../../styles/partials/colors.scss" as *;
@use "../../styles/partials/typography-mixins" as *;

.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: transparent;
  border: none;
  margin-bottom: 16rem;

  &__animation {
    position: relative;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 6px solid rgba(0, 0, 0, 0.1);
    border-top-color: $navy;
    animation: spin 1s infinite linear;
    margin: 1.5rem auto;
  }

  &__message {
    @include body-large;
    color: $navy;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

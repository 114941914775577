@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/typography-mixins" as *;
@use "../../styles/partials/variables" as *;
@use "../../styles/partials/colors" as *;

.logout {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: $mid-space-between;
  @include tablet {
    gap: $tablet-padding;
  }

  &__button {
    align-self: center;
    button {
      display: flex;
      align-items: center;
      gap: $smallest-space-between;
      @include labels-links-button;
      padding: $smallest-space-between $tablet-padding;
      background-color: $gold;
      margin-bottom: $smallest-space-between;
      border: none;
      border-radius: $oval-border-radius;
      cursor: pointer;
      box-shadow: 5px 5px $gray;
      transition: box-shadow 0.2s ease, transform 0.2s ease;
      // button effect: being pushed down on hover
      &:hover {
        box-shadow: 3px 3px $gray;
        transform: translateY(4px);
      }
    }
    &-icon {
      width: 26px;
    }
  }
}

@font-face {
  font-family: "Titillium Web";
  font-weight: 400;
  src: url("../../assets/fonts/TitilliumWeb-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Titillium Web";
  font-weight: 600;
  src: url("../../assets/fonts/TitilliumWeb-SemiBold.ttf") format("truetype");
}


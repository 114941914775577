@use "../../styles/partials/typography-mixins" as *;
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;
@use "../../styles/partials/colors" as *;

.gallery {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $mid-space-between;
  @include tablet {
    gap: $tablet-padding;
    align-items: flex-start;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: $small-space-between;

    &-title {
      @include header;
    }
  }
}

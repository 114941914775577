@use "../../styles/partials/typography-mixins" as *;
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;
@use "../../styles/partials/colors" as *;

.links {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $mid-space-between;
  @include tablet {
    align-items: flex-start;
    gap: $tablet-padding;
  }

  &__header {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    gap: $mobile-padding;
    @include tablet {
      flex-direction: row;
      justify-content: space-between;
      align-items: baseline;
    }

    &-title {
      @include header;
    }

    &-actions {
      display: flex;
      width: 100%;
      justify-content: space-evenly;
      @include tablet {
        justify-content: flex-end;
        gap: $mobile-padding;
      }
    }
  }
}

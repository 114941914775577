@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/typography-mixins" as *;
@use "../../styles/partials/variables" as *;

.album-form {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: $mid-space-between;
  @include tablet {
    gap: $tablet-padding;
  }

  &__body {
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: $mid-space-between;

    &-info {
      display: flex;
      flex-direction: column;
      gap: $mobile-padding;
      @include desktop {
        flex-direction: row;
        justify-content: space-between;
      }
    }
  }
}

@use "../../styles/partials/typography-mixins" as *;
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;
@use "../../styles/partials/colors" as *;

.album {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: $mobile-padding;
    @include tablet {
      align-items: flex-start;
    }

    &__header {
      text-align: center;
      @include body-large;
    }

    &__frame {
      cursor: pointer;
      align-self: center;
      background-color: $white;
      border: 1px solid $beige;
      border-radius: 10px;
      width: 100%;
      height: 50vh;
    }
  }
$tiny-space-between: 0.25rem;
$smallest-space-between: 0.5rem;
$small-space-between: 0.75rem;
$mobile-padding: 1rem;
$mid-space-between: 1.5rem;
$tablet-padding: 2rem;
$large-space-between: 3rem;
$larger-space-between: 4rem;
$largest-space-between: 5rem;
$desktop-padding: 8.125rem;
$oval-border-radius: 1.25rem;
$mobile-poster-width: 7rem;
$mobile-expand-poster-width: 14rem;
$tablet-poster-width: 18rem;
$mobile-banner-width: 10rem;
$tablet-banner-width: 15rem;
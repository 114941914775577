$cream:#f9f5f3; //background
$warm-cream: #f3ede7; //footer

$fade-dirty:#f0ebe5; //second layer in home
$dirty: #c3b9aa; //events segment text color tab
$dark-dirty: #807e6f; //footer rights, events segment text color tab on hover, border add button

$light-green: #dfe3d5; //add button and success message background
$green: #bfd7ae; //add button and submit button background on hover
$dark-green: #1c261e;

$sky: #e9faff;
$light-blue:#e1e5e6;
$blue: #8bb1b0;
$dark-blue:#6a8f8e;
$navy: #010128;

// $gold: #bd9662;
$gold:#d0b185;//submit,login,logout,register button background, join button hover animation, About VPA button background on hover at home page 
$beige: #a18053;

$light-red: #f4cecd;
$red: #c40906;
$dark-red:#4b180f;

$shade-black: #0000005c;
$fade-black: #333;
$black: #000000;

$white: #f7f4f4;

$light-silver: #e9e9e9;
$silver: #b5b6ba;
$gray: #7b767c;

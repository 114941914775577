@use "../../styles/partials/typography-mixins" as *;
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;
@use "../../styles/partials/colors" as *;

.join {
  display: flex;
  flex-direction: column;
  gap: $mobile-padding;
  @include tablet {
    gap: $tablet-padding;
  }

  &__intro {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: $mobile-padding;
    @include tablet {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    &-title {
      @include header;
      @include tablet {
        width: 60%;
      }
    }

    &-banner {
      // position: relative;
      // display: inline-block;
      img {
        // display: block;
        width: 18rem;
        overflow: hidden;
        aspect-ratio: 2;
        @include tablet {
          width: 20rem;
        }
        @include desktop {
          width: 25rem;
        }
      }
      //   &::after {
      //     content: '';
      //     position: absolute;
      //     top: 0;
      //     left: 0;
      //     right: 0;
      //     bottom: 0;
      //     background-color: #8bb1b06c; /* Adjust color and opacity here */
      //     pointer-events: none; /* Allows clicks to go through the overlay */
      // }

    }
  }

  &__section {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: $small-space-between;
    @include tablet {
      align-items: flex-start;
      gap: $mid-space-between;
    }

    &-header {
      @include subheader;
      font-weight: bold;
      text-align: center;
      @include tablet {
        text-align: left;
      }
    }

    &-content {
      @include body-large;
      &--bold {
        font-weight: bold;
      }
    }
  }
}

@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/typography-mixins" as *;
@use "../../styles/partials/variables" as *;
@use "../../styles/partials/colors" as *;

.group-form {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: $mid-space-between;
  @include tablet {
    gap: $tablet-padding;
  }

  &__body {
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: $mid-space-between;

    &-info {
      display: flex;
      flex-direction: column;
      gap: $mobile-padding;
      @include desktop {
        flex-direction: row;
        justify-content: space-between;
      }
    }
  }

  &__links {
    display: flex;
    width: 90%;
    flex-direction: column;
    gap: $mobile-padding;

    &-header {
      @include body-large;
      font-weight: bold;
      color: $beige;
    }
    &-item {
      text-decoration: underline;
      color: $fade-black;
      &:hover {
        cursor: pointer;
        font-weight: bold;
      }
    }
  }
}

@use "../../styles/partials/typography-mixins" as *;
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;
@use "../../styles/partials/colors" as *;

.admin {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $mid-space-between;
  @include tablet {
    gap: $tablet-padding;
  }
  &__header {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    gap: $small-space-between;
    &-icon {
      width: 100px;
    }
    &-title {
      @include header;
    }
    &-info {
      @include body-large;
      color: $fade-black;
      width: 90%;
      text-align: center;
    }
  }

  &__status {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    gap: $mid-space-between;

    &-title {
      @include subheader;
      color: $fade-black;
      text-align: center;
    }
    &-action {
      @include labels-links-button;
      display: flex;
      align-items: center;

      padding: $smallest-space-between $mobile-padding;
      background-color: $gold;
      border: none;
      border-radius: $oval-border-radius;
      gap: $smallest-space-between;
      box-shadow: 7px 7px $gray;
      transition: box-shadow 0.2s ease, transform 0.2s ease;
      //button effect: being pushed down on hover
      &:hover {
        cursor: pointer;
        box-shadow: 3px 3px $gray;
        transform: translateY(4px);
      }
    }
  }
}
